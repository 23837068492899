import React from "react"
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGoogleMap from "react-google-map"

const bnCoord = {
    lat: 35.6504915,
    lng: 139.7161517,
}

const Map = () => (
    <ReactGoogleMapLoader
        params={{
            key: "AIzaSyCD3lGm51oXMHTTuk8uw8se2oXbNljD5PI",
        }}
        style={{height: "100%"}}
        render={googleMaps => {
            return (
                googleMaps && (
                    <ReactGoogleMap
                        googleMaps={googleMaps}
                        coordinates={[
                            {
                                title: "42 Inc.",
                                position: bnCoord,
                            },
                        ]}
                        center={bnCoord}
                        zoom={16}
                    />
                )
            )
        }}
    />
)

  export default Map
